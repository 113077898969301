
import { eoRendererMixin, metaDataMixin } from '~/mixins'

export default {
  mixins: [
    eoRendererMixin,
    metaDataMixin,
  ],
  async asyncData ({ store, route }) {
    store.dispatch('breadcrumbs/set', {
      path: route.fullPath,
      floating: true,
      eoRoot: true,
      width: 10,
      items: [
        {
          label: 'Over de EO',
        },
      ],
    })
  },
  data () {
    return {
      hero: 'https://corporate-eo.cdn.eo.nl/{format}/6ayhs187q6a9-eojongerendag-2024-setvexy-svd-5121.jpg',
      seo: {
        title: 'Over de EO',
        description: 'Alles over de EO (Evangelische Omroep): vacatures, stages, persberichten, missie, bestuursdocumenten en contactinformatie.',
      },
    }
  },
}
